export const NAMESPACE = 'USER_CONTEXT'

const getType = step => {
  return `${NAMESPACE}${step}`
}

const REQUEST = '_REQUEST'
const SUCCESS = '_SUCCESS'
const FAILURE = '_FAILURE'
const SET_SITE_ID = '_SET_SITE_ID'

export const REQUEST_TYPE = getType(REQUEST)
export const SUCCESS_TYPE = getType(SUCCESS)
export const FAILURE_TYPE = getType(FAILURE)
export const SET_SITE_ID_TYPE = getType(SET_SITE_ID)

// Enable usage for saga lifecycle where name matches value
export default {
  [`${REQUEST_TYPE}`]: `${REQUEST_TYPE}`,
  [`${SUCCESS_TYPE}`]: `${SUCCESS_TYPE}`,
  [`${FAILURE_TYPE}`]: `${FAILURE_TYPE}`,
}
