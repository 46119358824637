import { createSelector } from 'reselect'
import { ADMIN, TEACHER } from './constants'

const selectUserContextManager = state => state.userContextManager
const selectRouter = state => state.router
const selectMiddleSchoolClasses = state =>
  state.lmsManager?.instructorData?.MiddleSchool ??
  state.lmsManager?.instructorData?.Classes
const selectIsAdminOnly = (_, isAdminsOnly) => isAdminsOnly

function stripUserContext(userContext) {
  // none of these fields in the userContext are being used by our current services:
  delete userContext.location
  delete userContext.paks

  return userContext
}

export const selectProgramContext = createSelector(
  [selectUserContextManager, selectRouter, selectMiddleSchoolClasses],
  (userContextManager, router, middleSchoolClasses) => {
    let context = {}
    const pathname = router?.location?.pathname
    const isPreview = pathname?.includes('preview')
    //we only want to include the program path for admin and teachers (NOT families)
    const isMsp = pathname?.includes('product/middle-school')

    //TODO: work with teams to decide if this needs to be "product/high-school" rather than "high-school"
    const isHighschool =
      pathname?.includes('high-school') ||
      pathname?.includes('program/hs') ||
      pathname?.includes('dashboard/grades-9-12')
    if (isMsp) {
      context = getMspContext(
        userContextManager,
        isPreview,
        middleSchoolClasses,
        router,
      )
    }
    if (isHighschool) {
      const queryParams = new URLSearchParams(router.location?.search)
      const isHighschoolPreview = queryParams.get('mode') === 'preview'
      context = getHighSchoolContext(userContextManager, isHighschoolPreview)
    }

    return context
  },
)

const _isBPUPreviewEnabled = claimsAndPreferences => {
  const claims = claimsAndPreferences.claims
  const preferences = claimsAndPreferences.preferences
  return claims.includes(ADMIN) && !preferences.bullyingHarassmentUnitEnabled
}

export const aggregateMSPSiteContext = userContextManager => {
  const userContextSites = userContextManager?.sites || []

  const mspClaims = new Set()
  const mspPreferences = {
    isSetup: false,
  }

  userContextSites.forEach(site => {
    //for middle school we want to include the highest level access for claims a user has AND exclude family claim from being returned
    if (site.claims) mspClaims.add(...site.claims)

    const siteMspPreferences = site?.programs?.mspDigital?.preferences
    if (siteMspPreferences) {
      mspPreferences.implementationLevel =
        mspPreferences.implementationLevel <
          siteMspPreferences.implementationLevel ||
        mspPreferences.implementationLevel == null
          ? siteMspPreferences.implementationLevel
          : mspPreferences.implementationLevel
      // if any site has bullyingHarassmentUnitEnabled, set it to true (otherwise, return the previous value of bullyingHarassmentUnitEnabled):
      mspPreferences.bullyingHarassmentUnitEnabled =
        !!siteMspPreferences.bullyingHarassmentUnitEnabled ||
        mspPreferences.bullyingHarassmentUnitEnabled == null
          ? siteMspPreferences.bullyingHarassmentUnitEnabled
          : mspPreferences.bullyingHarassmentUnitEnabled
      mspPreferences.isSetup = !!siteMspPreferences.isSetup
        ? siteMspPreferences.isSetup
        : mspPreferences.isSetup || false
    }
  })

  // remove nulls and undefineds from the set
  const nonNullClaims = Array.from(mspClaims).filter(value => value != null)

  return { claims: nonNullClaims, preferences: mspPreferences }
}

const getMspContext = (
  userContextManager,
  isPreview,
  middleSchoolClasses,
  router,
) => {
  if (!isPreview && router?.location?.pathname.includes('lessons/teach')) {
    const queryParams = new URLSearchParams(window.location.search || '')
    const courseInstanceId = queryParams.get('class')
    const matchingCourse = middleSchoolClasses?.find(
      course => course.instance === courseInstanceId,
    )
    if (!matchingCourse) {
      console.warn('no matching course found. Returning full user context.')
      return userContextManager
    }
    const siteWithCourse = structuredClone(
      userContextManager?.sites?.find(
        site => site.siteId === matchingCourse.siteId,
      ),
    )
    const mspDigital = siteWithCourse.programs.mspDigital
    // get rid of all the other programs and just send middle school:
    siteWithCourse.programs = { mspDigital }

    return {
      ...stripUserContext(userContextManager),
      sites: [siteWithCourse],
    }
  }

  const aggregateClaimsAndPreferences = aggregateMSPSiteContext(
    userContextManager,
  )
  const minimalUserContext = stripUserContext({
    ...userContextManager,
  })

  // if we're not in an instance, just always return the aggregated context for middle school with first MSP site + mutated preferences:
  const aggregatedSite = structuredClone(
    userContextManager?.sites?.find(site => !!site.programs.mspDigital),
  )

  aggregatedSite.claims = aggregateClaimsAndPreferences.claims || []

  // null coalescing plug-in was causing problems but still needed to check
  // due to depth level concerns
  const { programs } = aggregatedSite || {}
  const { mspDigital: aggregatedMspDigital } = programs || {}
  aggregatedMspDigital.preferences =
    aggregateClaimsAndPreferences.preferences || {}

  // This is telling all of program flex (not just route service) to deliver the B and H content (not just route tree).
  // If you tried to get to the page content for this from content-service AND from course manager both of those systems
  // should remove the B and H content (or deny you)
  const isBPUPreviewEnabled = _isBPUPreviewEnabled(
    aggregateClaimsAndPreferences,
  )
  const { preferences: aggregatedMspPreferences } = aggregatedMspDigital || {}
  aggregatedMspPreferences.bullyingHarassmentUnitEnabled = !!aggregatedMspPreferences.bullyingHarassmentUnitEnabled
    ? aggregatedMspPreferences.bullyingHarassmentUnitEnabled
    : isBPUPreviewEnabled

  const mspDigital = aggregatedMspDigital
  // get rid of all the other programs and just send middle school:
  aggregatedSite.programs = { mspDigital }
  return {
    ...minimalUserContext,
    sites: [aggregatedSite],
  }
}

const getHighSchoolContext = (userContextManager, isPreview) => {
  // add in this hack to select a site id automatically as the first available high school site:
  // this is placeholder until we have user-selectable site ids
  if (!userContextManager.hsActiveSiteId && !userContextManager.isFetching) {
    const highSchoolSites = userContextManager?.sites?.filter(
      site => !!site.programs.highSchool,
    )
    if (highSchoolSites.length === 0) {
      throw new Error('no high school site available')
    }
    userContextManager.hsActiveSiteId = highSchoolSites[0].siteId
  }
  const sites = userContextManager?.sites
  const site = sites?.find(
    site => site.siteId === userContextManager.hsActiveSiteId,
  )

  if (!site) {
    console.warn('no high school site found.')
    return null
  }

  if (site?.programs?.highSchool) {
    // we don't want to include any program but highSchool in a High School user context:
    site.programs = { highSchool: site.programs.highSchool }
  }
  if (site?.programs?.highSchool?.courses) {
    // we don't want to include any course but highschool in a High School user context:
    site.programs.highSchool.courses = site.programs.highSchool.courses.filter(c => c?.name === 'highschool')
  }
  const highSchoolPreferences = { ...site?.programs?.highSchool?.preferences }
  if (highSchoolPreferences && highSchoolPreferences.studentActivities === undefined) {
    // we should always include a value for studentActivities or requests to course-manager will fail due to how high school is audienced
    site.programs.highSchool.preferences.studentActivities = false
  }

  const minimalUserContext = stripUserContext({
    ...userContextManager,
  })

  if (isPreview) {
    highSchoolPreferences.implementationLevel = 2
    highSchoolPreferences.studentActivities = true

    const previewSite = structuredClone(site)
    previewSite.programs.highSchool.preferences = highSchoolPreferences
    const highSchool = previewSite.programs.highSchool
    // get rid of all the other programs and just send high school:
    previewSite.programs = { highSchool }
    return {
      ...minimalUserContext,
      sites: [previewSite],
    }
  }
  return {
    ...minimalUserContext,
    sites: [site],
    contextType: 'highschool',
  }
}

// TODO: remove once sites/me surfaces sitePreferences.highSchool.areUsersAdded
const selectHighSchoolSiteIdAndPreferences = createSelector(
  [selectUserContextManager, selectRouter],
  (userContextManager, router) => {
    const pathname = router?.location?.pathname
    const isHighschool = pathname?.includes('admin/program/hs')

    if (isHighschool) {
      const highSchoolSites = userContextManager?.sites?.filter(
        site => !!site.programs.highSchool,
      )
      const hsSitesAndPreferences = highSchoolSites?.map(site => {
        return {
          siteId: site?.siteId,
          preferences: site?.programs?.highSchool?.preferences,
        }
      })
      return hsSitesAndPreferences
    }
    return null
  },
)

export function defaultUserContextFilter(userContext) {
  return userContext
}

export function filterUniqueUserContextFields(userContext) {
  // clone so we don't mutate siteId in the state tree
  const clonedContext = structuredClone(userContext)
  const filteredSiteId = clonedContext?.sites?.map(site => {
    site?.siteId && delete site.siteId

    return site
  })

  return { sites: filteredSiteId }
}

// consolidates claims and preferences to their
// "highest level"for MSP into one object
// {
//   claims: string[],
//   implementationLevel: number,
//   bullyingHarassmentUnitEnabled: boolean,
//   isSetup: boolean
// }
export const filterToAggregateMSPSiteContext = userContextSites => {
  if (!userContextSites) return
  return {
    claims: userContextSites?.reduce((mspContext, currentSite) => {
      const currentClaims = currentSite?.claims
      if (!currentClaims) {
        return mspContext
      }

      // we dont want "family" to be returned yet in the ms program fetch
      if (
        Array.isArray(currentClaims) &&
        (currentClaims.includes(ADMIN) || currentClaims.includes(TEACHER))
      ) {
        return currentClaims
      }

      return mspContext
    }, []),
    ...userContextSites?.reduce((mspContext, currentSite) => {
      const currentMspPreferences =
        currentSite?.programs?.mspDigital?.preferences
      if (!currentMspPreferences) return mspContext
      // coalescing all the MSP preferences into one object where the highest value is set for any given preference
      mspContext.implementationLevel =
        mspContext.implementationLevel <
        currentMspPreferences.implementationLevel
          ? currentMspPreferences.implementationLevel
          : mspContext.implementationLevel
      // if any site has bullyingHarassmentUnitEnabled, set it to true
      // (otherwise, return the previous value of bullyingHarassmentUnitEnabled):
      mspContext.bullyingHarassmentUnitEnabled = currentMspPreferences.bullyingHarassmentUnitEnabled
        ? currentMspPreferences.bullyingHarassmentUnitEnabled
        : mspContext.bullyingHarassmentUnitEnabled
      mspContext.isSetup = currentMspPreferences.isSetup
        ? currentMspPreferences.isSetup
        : mspContext.isSetup

      return mspContext
    }, {}),
  }
}

export const selectMspSiteContexts = createSelector(
  [selectUserContextManager, selectIsAdminOnly],
  (userContextManager, isAdminsOnly = false) => {
    let mspSiteContexts = userContextManager?.sites?.reduce(
      (sitesWithMsp, currentSite) => {
        if (currentSite?.programs?.['mspDigital']) {
          if (isAdminsOnly && currentSite.claims?.includes(ADMIN)) {
            sitesWithMsp.push(currentSite)
          } else if (!isAdminsOnly) {
            sitesWithMsp.push(currentSite)
          }
        }
        return sitesWithMsp
      },
      [],
    )

    return mspSiteContexts
  },
)

export default {
  selectHighSchoolSiteIdAndPreferences,
  selectProgramContext,
  selectMspSiteContexts,
}
