import LogBase from '../LogBase'
import { initAppInsightsPlugin } from '../Libs'
import {
  ApplicationInsights,
  SeverityLevel,
  Telemetry,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'

class Logger extends LogBase {
  init(history) {
    process.env.APP_INSIGHTS_INSTRUMENTATION_KEY
      ? this.initAppInsights(history)
      : console.error('Missing App Insights Key')
  }

  initAppInsights = history => {
    const reactPlugin = new ReactPlugin()
    const clickPlugin = new ClickAnalyticsPlugin()
    const clickPluginConfig = {
      autoCapture: true,
      dataTags: {
        useDefaultContentNameOrId: true,
        customDataPrefix: 'data-test',
      },
    }

    const appInsightsConfig = {
      config: {
        instrumentationKey: process.env.APP_INSIGHTS_INSTRUMENTATION_KEY,
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: [
          '*media.azure.net',
          '*launchdarkly.com',
          '*blob.core.windows.net',
          '*google-analytics.com',
          'login-np-ci.cfctest.org',
          'login-np-dev.cfctest.org',
          'login-np-qa.cfctest.org',
          'login-np-staging.cfctest.org',
          'login-np-constg.cfctest.org',
          'login-tr-env.tftransitory.org',
          'login.secondstep.org',
          '*yctn0hpois-dsn.algolia.net',
          '*ctfassets.net',
        ],
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin, clickPlugin],
        extensionConfig: {
          [clickPlugin.identifier]: clickPluginConfig,
        },
      },
    }
    if (history) {
      appInsightsConfig.config.extensionConfig = {
        ...appInsightsConfig.config.extensionConfig,
        [reactPlugin.identifier]: { history: history },
      }
    }

    this.loggerInstance = new ApplicationInsights(appInsightsConfig)
    this.loggerInstance.loadAppInsights()
    window.appInsight = this.loggerInstance
    this.loggerInstance.addTelemetryInitializer(this.transformTelemetry)
    this.loggerInstance.trackPageView()
  }

  isKnownError = telemetryItem => {
    const knownErrorList = [
      "Uncaught SyntaxError: Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id=",
    ]
    if (!telemetryItem.exceptions) {
      return false
    }

    return telemetryItem.exceptions.some(e =>
      knownErrorList.includes(e.message),
    )
  }

  /* istanbul ignore next */
  transformTelemetry = envelope => {
    if (envelope.name === Telemetry.Exception.envelopeType) {
      const telemetryItem = envelope.baseData
      // Set severity level to warning for known errors
      if (this.isKnownError(telemetryItem)) {
        telemetryItem.severityLevel = SeverityLevel.Warning
      }
    }
    // Handle page views
    if (envelope.name === Telemetry.PageView.envelopeType) {
      const telemetryItem = envelope.baseData
      telemetryItem.name = telemetryItem.uri
    }
  }

  setUserId = this.checkForInitialization(userId => {
    this.loggerInstance.setAuthenticatedUserContext(userId)
  })

  logEvent = this.checkForInitialization(event => {
    this.loggerInstance.trackEvent(event)
  })

  logException = this.checkForInitialization(event => {
    this.loggerInstance.trackException(event)
  })

  logPageView = this.checkForInitialization(name => {
    this.loggerInstance.trackPageView({ name })
  })

  loadAppInsightsPlugin = (instanceRef, player, settings) => {
    initAppInsightsPlugin(instanceRef, player, settings)
    return this
  }
}

export default new Logger()
