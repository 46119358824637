import { hasParentApiInstance } from '../APIConnector'

// Main app configuration

export const H5P_IMG_ORIGIN = 'https://activities-secondstep.azureedge.net'
export const HOST_DOMAIN = process.env.HOST_DOMAIN
export const SUBDOMAIN = process.env.SUBDOMAIN
export const CONTENT_FUNCTION_URL = `https://func-apps${SUBDOMAIN}-contentful-func.${HOST_DOMAIN}/api/GetContentfulResource`
export const CONTENTFUL_URL =
  'https://app.contentful.com/spaces/wjuty07n9kzp/entries/'
export const USER_CONTEXT_URL = `https://api${SUBDOMAIN}.${HOST_DOMAIN}/v3/`
export const IS_CONNECTED_TO_API = hasParentApiInstance()
export const LOAD_CONTENTFUL_IMAGES_PROGRESSIVELY = false
const LD_CLIENT_ID = process.env.LD_CLIENT_ID
export const LD_CONFIG = {
  clientSideID: LD_CLIENT_ID,
  user: {
    email: 'fakePlayer@notrealdomain.or',
    kind: 'user',
    key: 'default_fake_player_user_key',
    name: 'Default Fake Player User',
  },
}

const { parent } = window || {}
export const { __CFC_PLAYER_DATA__: PLAYER_INSTANCE } = parent || {}
